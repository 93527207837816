import React, { Component } from 'react'

import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Container,
    Col,
    Form,
    FormGroup,
    FormText,
    Input,
    Label,
    Row,
    Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap'
import NumberFormat from 'react-number-format';
import axios from 'axios'
import { apiurl } from '../../../../config/api'
import Moment from "react-moment";
import {connect} from "react-redux";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Slide from "@material-ui/core/Slide";
const FLAVOURS = [
    { label: 'Chocolate', value: 'chocolate' },
    { label: 'Vanilla', value: 'vanilla' },
    { label: 'Strawberry', value: 'strawberry' },
    { label: 'Caramel', value: 'caramel' },
    { label: 'Cookies and Cream', value: 'cookiescream' },
    { label: 'Peppermint', value: 'peppermint' }
]

class Public_error extends Component {
    handleChange = e => {
        const name = e.target.name
        const value = e.target.value
        this.setState(prevstate => {
            const newState = { ...prevstate }
            newState[name] = value
            return newState
        })
    }

    constructor(props) {
        super(props)
        this.toggle = this.toggle.bind(this)
        this.toggleFade = this.toggleFade.bind(this)
        this.state = {
            collapse: true,
            fadeIn: true,
            timeout: 300,
            itemlist: [],
            description: '',
            title: '',
            Productdata: [],
            FLAVOURS: [],
            showingdata: {},
            once: true,
            iscompany: false,
            isbilling: false,
            billingID:'',
            billingREF_1:'',
            billingREF_2:'',
            companyname:'',
            address:'',
            tel:'',
            taxid:'',
            req_firstname:'',
            req_lastname:'',
            req_citizenid:'',
            req_email:'',
            typeWork:'',
            typeProduct:'',
            typePay:'',
            modal: false,
            image: '',
            file: null,
            number: '',
            payed: false,
            payDate:'',
            refund: false,
            refundDate: '',
            chanelpay:'',
            paynumber:'',
            open2:false,
            modalA:false,
            modalC:false,
            chanelsend:'',
            reasonapprove:'',
            unapprove:''
        }
    }

    componentDidMount () {
        const myHeaders = new Headers()
        myHeaders.append('Content-Type', 'application/json')
        myHeaders.append('Authorization', 'Bearer '+this.props.authToken)
        fetch(apiurl + '/core/public/'+this.props.match.params.id+'/', { method: 'GET', headers: myHeaders })
            .then(response => response.json())
            .then(dataout => this.setState({
                    iscompany: dataout.iscompany,
                    companyname:dataout.companyname,
                    address:dataout.address,
                    companytel:dataout.companytel,
                    taxid:dataout.taxid,

                    req_firstname:dataout.firstname,
                    req_lastname:dataout.lastname,
                    req_citizenid:dataout.citizenid,
                    req_email:dataout.email,
                    req_tel:dataout.tel,
                })
            )
    }

    setitem= (item) => {
        var temp = this.state.itemlist
        temp.push(item)
        this.setState({ itemlist: temp })
    }

    onConfirm= (order) => {
        this.setState({ itemlist: order })
    }

    toggle() {
        this.setState({ collapse: !this.state.collapse })
    }

    toggleFade() {
        this.setState((prevState) => { return { fadeIn: !prevState } })
    }

    handleChangeimages = (event) =>{
        this.setState({
            image: URL.createObjectURL(event.target.files[0]),
            file: event.target.files[0]
        })
    }


    approve = (e) => {
        e.preventDefault()
        console.log(this.state)
        // eslint-disable-next-line camelcase
        const form_data = new FormData()
        form_data.append('reasonapprove', this.state.reasonapprove)
        form_data.append('chanelsend', this.state.chanelsend)
        const url = apiurl + '/mopth/ticket/approve/' + this.props.match.params.id + '/'
        axios.POST(url,form_data, {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': 'Bearer '+this.props.authToken
            }
        })
            .then(res => {
                console.log(res.data)
                window.location.reload()
            })
            .catch(err => console.log(err))
    }

    render() {;
        return (
            <div className="animated fadeIn">
                <Container className="themed-container">
                    <Row>
                        <Col xs="12" sm="12" lg="12">
                            <Card>
                                <CardHeader>
                                    <strong>ข้อมูลคำขอ</strong>
                                </CardHeader>
                                <CardBody>
                                    <Form action="" method="post" encType="multipart/form-data" className="form-horizontal">
                                        <h1 style={{color:"red"}}>ไม่พบข้อมูลคำร้องขอ</h1>
                                        <br/>
                                        <br/>
                                        <h4>หากมีข้อส่งสัยในการใช้งานระบบติดต่อสายด่วนเบอร์ 061-3910600</h4>
                                        <br/>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

            </div>
        )
    }
}
const mapStateToProps = ({ auth: { user,authToken } }) => ({
    user,authToken
});

export default connect(mapStateToProps)(Public_error);
