import React ,{Component}from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {ButtonBase} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {Button, Form, InputGroup, Col, Row} from "react-bootstrap";
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia'
const images = [
    {
        color: '#3498db',
        title: 'อย',
        width: '50%',
    },
    {
        color: '#2ecc71',
        title: 'สสจ',
        width: '50%',
    },
];

export default class SigglepageMOPH extends Component{

    routeChange(e, path )  {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        this.props.history.push(path)
    }

    render() {
        return (
            <React.Fragment>
                <CssBaseline/>
                <Container fixed>
                    <Grid container spacing={5}>
                        <Grid item xs={12} lg={6}>
                            <Card style={{background:"#e74c3c",color:"white"}}>
                                <CardActionArea onClick={e => this.routeChange(e,'/singlepage/')}>
                                    <CardMedia
                                        image="/static/images/cards/contemplative-reptile.jpg"
                                        title="Contemplative Reptile"
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="h2">
                                            ย้อนกลับ
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Card >
                                <CardActionArea onClick={e => this.routeChange(e,'/moph/billing')}>
                                    <CardMedia
                                        image="/static/images/cards/contemplative-reptile.jpg"
                                        title="Contemplative Reptile"
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="h2">
                                            ออกใบสั่งชำระเงิน
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    </Grid>
                    <Grid container spacing={5}>
                        <Grid item xs={12} lg={6}>
                            <Card >
                                <CardActionArea onClick={e => this.routeChange(e,'/moph/billing/all')}>
                                    <CardMedia
                                        image="/static/images/cards/contemplative-reptile.jpg"
                                        title="Contemplative Reptile"
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="h2">
                                            ใบสั่งชำระเงินทั้งหมด
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Card>
                                <CardActionArea onClick={e => this.routeChange(e,'/moph/calendar')}>
                                    <CardMedia
                                        image="/static/images/cards/contemplative-reptile.jpg"
                                        title="Contemplative Reptile"
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="h2">
                                            ใบนัดหมาย
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    </Grid>
                    <Grid container spacing={5}>
                        <Grid item xs={12} lg={6}>
                            <Card >
                                <CardActionArea onClick={e => this.routeChange(e,'/moph/billing/dayreport')}>
                                    <CardMedia
                                        image="/static/images/cards/contemplative-reptile.jpg"
                                        title="Contemplative Reptile"
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="h2">
                                            รายงานชำระเงินประจำวัน
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </React.Fragment>
        );
    }

}


