
import React, {Component} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import {FormControl,InputLabel,OutlinedInput,Switch} from '@material-ui/core'
import Select from 'react-select';
import { Button, Form, InputGroup, Col, Row, Nav, Tab } from "react-bootstrap";
import { apiurl } from '../../config/api'
import axios from 'axios'
import {connect} from "react-redux";
const qustion = [
  {
    'title':'',
    'name':''
  }
]


class ChnagePassword extends Component {

  constructor(props) {
    super(props);
    this.state = {
      passwordold:'',
      passwordnew:'',
      passwordnewcom:''
    };
  }

  handleChange = e => {
    const name = e.target.name
    const value = e.target.value
    this.setState(prevstate => {
      const newState = { ...prevstate }
      newState[name] = value
      return newState
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    var data = {
      passwordold:this.state.passwordold,
      passwordnew:this.state.passwordnew,
      passwordnewcom:this.state.passwordnewcom,
    }
    const url = apiurl + '/mopth/billing/create/'
    console.log(url)
    axios.post(url, JSON.stringify(data), {
      headers: {
        'content-type': 'application/json'
      }
    })
        .then(res => {
          console.log(res.data)
          alert('สร้างใบสั่งชำระสำเร็จ')
          this.props.history.push('/moph/billing/all')
        })
        .catch(err => alert('สร้างใบสั่งชำระไม่ได้กรุณาติดต่อเจ้าหน้าที่'))
  }

  render() {
    return (
        <React.Fragment>
          <CssBaseline />
          <Container fixed>
            <Card style={{overflow:'warp'}}>
              <CardContent>
                <h4>เปลี่ยนรหัสผ่าน</h4>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={6} md={6} >
                    <Form.Group controlId="formBasicPassword">
                      <Form.Label>รหัสผ่านเก่า</Form.Label>
                      <Form.Control type="text" placeholder="" name="passwordold" onChange={this.handleChange}/>
                    </Form.Group>
                  </Grid>
                  <Grid item xs={12} lg={6} md={6}>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Label>รหัสผ่านใหม่</Form.Label>
                      <Form.Control type="text" placeholder="" name="passwordnew" onChange={this.handleChange}/>
                    </Form.Group>
                  </Grid>
                  <Grid item xs={12} lg={6} md={6}>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Label>ยืนยันรหัสผ่านใหม่</Form.Label>
                      <Form.Control type="text"
                                    placeholder="" name="passwordnewcom" onChange={this.handleChange} value={this.state.passwordnewcom}/>
                    </Form.Group>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={12} md={12}>
                    <Button variant="primary" onClick={this.handleSubmit}>เปลี่ยนรหัสผ่าน</Button>
                    <br/>
                    <br/>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Container>
        </React.Fragment>

    );
  }
}
const mapStateToProps = ({ auth: { user,authToken } }) => ({
  user,authToken
});
export default connect(mapStateToProps)(ChnagePassword);
