import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Dashboard from "./Dashboard";
import Sigglepage from "./singlepage";
import DocsPage from "./docs/DocsPage";
import SigglepageSSJ from "./singlepage/ssj"
import SigglepageMOPH from "./singlepage/moph"
import ChangePassword from "./changepassword"
import { LayoutSplashScreen } from "../../../_metronic";

const mophpage = lazy(() =>
    import("./moph/mophpage")
);
const ssjpage = lazy(() =>
    import("./ssj/ssjpage")
);
export default function HomePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/ssj/calendar/" />
        }
        {
          /*
          <Route path="/singlepage" exact component={Sigglepage} />
          <Route path="/singlepage/ssj" exact component={SigglepageSSJ} />
          <Route path="/singlepage/moph" exact component={SigglepageMOPH} />
          <Route path="/moph"  component={mophpage} />
          */
        }
        <Redirect exact from="/singlepage" to="/ssj/calendar/" />
        <Route path="/ssj"  component={ssjpage} />
        <Route path="/changepassword"  component={ChangePassword} />
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
