import React ,{Component}from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {ButtonBase} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {Button, Form, InputGroup, Col, Row} from "react-bootstrap";
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia'
import {connect} from "react-redux";

const images = [
    {
        color: '#3498db',
        title: 'อย',
        width: '50%',
    },
    {
        color: '#2ecc71',
        title: 'สสจ',
        width: '50%',
    },
];


class Sigglepage extends Component{

    routeChange(e, path )  {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        this.props.history.push(path)
    }

    render() {
        const { user } = this.props;
        return (
            <React.Fragment>
                <CssBaseline/>
                <Container fixed>
                    <Grid container spacing={5}>
                        {user.role === 'ssj' && <>
                            <Grid item xs={12} lg={6}>
                                <Card>
                                    <CardActionArea onClick={e => this.routeChange(e,'/singlepage/ssj/')}>
                                        <CardMedia
                                            image="/static/images/cards/contemplative-reptile.jpg"
                                            title="Contemplative Reptile"
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                ระบบ สสจ
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                เข้าใช้งานระบบ สสจ
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        </>}
                        {user.role === 'superadmin' && <>
                            <Grid item xs={12} lg={6}>
                                <Card>
                                    <CardActionArea onClick={e => this.routeChange(e,'/singlepage/moph/')}>
                                        <CardMedia
                                            image="/static/images/cards/contemplative-reptile.jpg"
                                            title="Contemplative Reptile"
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                ระบบ อย
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                เข้าใช้งานระบบ อย
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Card>
                                    <CardActionArea onClick={e => this.routeChange(e,'/singlepage/ssj/')}>
                                        <CardMedia
                                            image="/static/images/cards/contemplative-reptile.jpg"
                                            title="Contemplative Reptile"
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                ระบบ สสจ
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                เข้าใช้งานระบบ สสจ
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        </>}
                        {user.role === 'mopth' && <>
                            <Grid item xs={12} lg={6}>
                                <Card>
                                    <CardActionArea onClick={e => this.routeChange(e,'/singlepage/moph/')}>
                                        <CardMedia
                                            image="/static/images/cards/contemplative-reptile.jpg"
                                            title="Contemplative Reptile"
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                ระบบ อย
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                เข้าใช้งานระบบ อย
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        </>}
                    </Grid>
                </Container>
            </React.Fragment>
        );
    }

}
const mapStateToProps = ({ auth: { user } }) => ({
    user
});

export default connect(mapStateToProps)(Sigglepage);
