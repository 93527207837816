import axios from "axios";
import { apiurl } from '../config/api'
export const LOGIN_URL = "/authen/api/token/";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";

export const ME_URL = "/authen/api/user/";



export function login(username, password) {
  return axios.post(apiurl+LOGIN_URL, { username, password });
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(apiurl+ME_URL);
}
